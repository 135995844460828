'use strict';

// TODO: Remove from `core-js@4`
var $ = require('../internals/export');
var $group = require('../internals/array-group');
var arrayMethodIsStrict = require('../internals/array-method-is-strict');
var addToUnscopables = require('../internals/add-to-unscopables');

// `Array.prototype.groupBy` method
// https://github.com/tc39/proposal-array-grouping
// https://bugs.webkit.org/show_bug.cgi?id=236541
$({
  target: 'Array',
  proto: true,
  forced: !arrayMethodIsStrict('groupBy')
}, {
  groupBy: function groupBy(callbackfn /* , thisArg */) {
    var thisArg = arguments.length > 1 ? arguments[1] : undefined;
    return $group(this, callbackfn, thisArg);
  }
});
addToUnscopables('groupBy');