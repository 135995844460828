'use strict';

var $ = require('../internals/export');
var fromAsync = require('../internals/array-from-async');
var fails = require('../internals/fails');
var nativeFromAsync = Array.fromAsync;
// https://bugs.webkit.org/show_bug.cgi?id=271703
var INCORRECT_CONSTRUCTURING = !nativeFromAsync || fails(function () {
  var counter = 0;
  nativeFromAsync.call(function () {
    counter++;
    return [];
  }, {
    length: 0
  });
  return counter !== 1;
});

// `Array.fromAsync` method
// https://github.com/tc39/proposal-array-from-async
$({
  target: 'Array',
  stat: true,
  forced: INCORRECT_CONSTRUCTURING
}, {
  fromAsync: fromAsync
});