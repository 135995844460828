/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (<any> window).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (<any> window).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (<any> window).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (<any> window).__Zone_enable_cross_context_check = true;
 *
 */

(<any> window).__Zone_disable_requestAnimationFrame = true;

(<any> window).__Zone_disable_MutationObserver = true;

(<any> window).__Zone_disable_IntersectionObserver = true;

(<any> window).__Zone_disable_mediaQuery = true;

(<any>window).__zone_symbol__UNPATCHED_EVENTS = [
	'scroll',
	'mousemove',
];
