'use strict';

require('../../modules/es.array.from');
require('../../modules/es.array.is-array');
require('../../modules/es.array.of');
require('../../modules/es.array.at');
require('../../modules/es.array.concat');
require('../../modules/es.array.copy-within');
require('../../modules/es.array.every');
require('../../modules/es.array.fill');
require('../../modules/es.array.filter');
require('../../modules/es.array.find');
require('../../modules/es.array.find-index');
require('../../modules/es.array.find-last');
require('../../modules/es.array.find-last-index');
require('../../modules/es.array.flat');
require('../../modules/es.array.flat-map');
require('../../modules/es.array.for-each');
require('../../modules/es.array.includes');
require('../../modules/es.array.index-of');
require('../../modules/es.array.iterator');
require('../../modules/es.array.join');
require('../../modules/es.array.last-index-of');
require('../../modules/es.array.map');
require('../../modules/es.array.push');
require('../../modules/es.array.reduce');
require('../../modules/es.array.reduce-right');
require('../../modules/es.array.reverse');
require('../../modules/es.array.slice');
require('../../modules/es.array.some');
require('../../modules/es.array.sort');
require('../../modules/es.array.species');
require('../../modules/es.array.splice');
require('../../modules/es.array.to-reversed');
require('../../modules/es.array.to-sorted');
require('../../modules/es.array.to-spliced');
require('../../modules/es.array.unscopables.flat');
require('../../modules/es.array.unscopables.flat-map');
require('../../modules/es.array.unshift');
require('../../modules/es.array.with');
require('../../modules/es.object.to-string');
require('../../modules/es.string.iterator');
var path = require('../../internals/path');
module.exports = path.Array;